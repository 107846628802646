import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { IEntity } from './types'
import type {
  INote,
  ICreateNoteRequest,
  IUpdateNoteRequest,
  ILeaseRegisterLease,
} from '@register'

// GET /countries
export const useApiNotes = (entity: IEntity, entityId: string) => {
  const queryClient = useQueryClient()
  const dayjs = useDayjs()
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  // GET all notes from an entity
  const getNotes = useQuery<INote[]>({
    queryKey: ['notes', entity, entityId],
    queryFn: () =>
      fetchAPI<INote[]>(`/entities/${entity}/${entityId}/notes`).then((res) =>
        res.sort((a, b) =>
          dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1,
        ),
      ),
  })

  const createNote = useMutation({
    mutationFn: (body: ICreateNoteRequest) =>
      fetchAPI(`/entities/${entity}/${entityId}/notes`, {
        method: 'POST',
        body,
      }),
    onSuccess: (note: INote) => {
      try {
        //update the model optimistically
        queryClient.setQueryData(
          ['notes', entity, entityId],
          (oldNotes: INote[] | undefined) => {
            return oldNotes ? [note, ...oldNotes] : [note]
          },
        )

        // Invalidate summary
        queryClient.invalidateQueries({
          queryKey: getApiLeaseSummaryKey(divisionId, leaseId),
        })

        // Invalidate Lease Document
        queryClient.invalidateQueries({
          queryKey: ['companies', divisionId, 'leases', leaseId],
          exact: true,
        })
        queryClient.invalidateQueries({
          queryKey: getLeaseDocumentKey(divisionId, leaseId),
          exact: true,
        })
      } catch (err) {
        console.error(err)
      }
    },
  })

  const updateNote = useMutation({
    mutationFn: (body: IUpdateNoteRequest) =>
      fetchAPI(`/entities/${entity}/${entityId}/notes`, {
        method: 'PUT',
        body,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', entity, entityId] })
    },
  })

  const deleteNote = useMutation({
    mutationFn: (noteId: string) =>
      fetchAPI(`/notes/${noteId}`, {
        method: 'DELETE',
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notes', entity] })
      await queryClient.invalidateQueries({
        queryKey: ['companies', divisionId, 'leases', leaseId],
        exact: true,
      })
    },
  })

  return {
    getNotes,
    createNote,
    updateNote,
    deleteNote,
  }
}
